<template>
  <section>
    <button 
      @click="deleted()"
      type="button" 
      class="btn btn-icon btn-rounded btn-youtube">
      <i class="fa fa-trash-o"></i>
    </button>
  </section>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      status: false
    }
  },
  methods: {
    deleted() {
      this.$swal({
        title: 'แจ้งเตือน',
        text: 'คุณต้องการลบข้อมูลใช่หรือไม่',
        type: 'error',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        showCloseButton: true,
        width: '23rem',
        allowOutsideClick: false,
        focusConfirm: false,
        customClass: {
          title: 'd-none',
          confirmButton: 'btn btn-fw font-prompt-light text-sm font-normal rounded-lg',
          cancelButton: 'btn btn-fw font-prompt-light text-sm font-normal rounded-lg'
        },
      }).then(result => {
        if (result.value) {
          this.$emit('deletereply', {
            id: this.id,
            endpoint: this.url,
            result: result
          })
        }
      })
    }
  }
}
</script>
